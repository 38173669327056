
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { redirect } from "@/utils/redirect";

import Enum from "@/utils/enum";
import { Authorization } from "@/typings/client.typings";
import {
  AuthMessages,
  LoginActions,
  QueryParameterNames,
} from "@/store/modules/authorization/constants";
import {
  AuthenticationResultStatus,
  AuthorizationModule,
  State,
} from "@/store/modules/authorization";

@Component
export default class Login extends Vue {
  // #region Data ---------------------------------------------------------------------------------------

  // Component Properties -----------------------------

  @Prop({
    required: true,
    validator: (value) => Enum.IsValid(LoginActions, value),
  })
  readonly action!: LoginActions;

  // Local variables ----------------------------------

  alertType = Authorization.AlertType.Info;
  message = "";

  // #endregion

  // #region Methods ------------------------------------------------------------------------------------

  // Life Cycles --------------------------------------

  async created(): Promise<void> {
    console.log(this.alertType);

    switch (this.action) {
      case LoginActions.Login:
        this.message = AuthMessages.ProcessingLogin;
        this.alertType = Authorization.AlertType.Info;
        await this.login(this.getReturnUrl());
        break;

      case LoginActions.LoginCallback:
        this.message = AuthMessages.ProcessingLoginCallback;
        this.alertType = Authorization.AlertType.Info;
        await this.processLoginCallback();
        break;

      case LoginActions.LoginFailed:
        {
          const params = new URLSearchParams(window.location.search);
          const error = params.get(QueryParameterNames.Message);
          this.message = error || AuthMessages.LoginFailed;
        }
        break;

      case LoginActions.Profile:
        this.redirectToProfile();
        break;

      case LoginActions.Register:
        this.redirectToRegister();
        break;

      default:
        throw new Error(`Invalid action '${this.action}'.`);
    }
  }

  // Local methods ------------------------------------

  getReturnUrl(state?: State): string {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);

    if (
      fromQuery &&
      !fromQuery.startsWith("/") &&
      !fromQuery.startsWith(`${window.location.origin}/`)
    ) {
      throw new Error(
        "Invalid return url. The return url needs to have the same origin as the current page."
      );
    }

    return (
      (state && state.returnUrl) || fromQuery || `${window.location.origin}/`
    );
  }

  async login(returnUrl: string): Promise<void> {
    const result = await AuthorizationModule.signInAsync({ returnUrl });
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        break;

      case AuthenticationResultStatus.Success:
        redirect(returnUrl);
        break;

      case AuthenticationResultStatus.Fail:
        this.message = result.message || "";
        break;

      default:
        throw new Error(`Invalid status result '${result.status}'.`);
    }
  }

  async processLoginCallback(): Promise<void> {
    const result = await AuthorizationModule.completeSignInAsync(
      window.location.href
    );

    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeSignIn finishes
        // is when we are doing a redirect sign in flow.
        break;

      case AuthenticationResultStatus.Success:
        redirect(this.getReturnUrl(result.state));
        break;

      case AuthenticationResultStatus.Fail:
        this.message = result.message || "";
        break;

      default:
        throw new Error(
          `Invalid authentication result status: '${result.status}'.`
        );
    }
  }

  redirectToApiAuthorizationPath(apiAuthorizationPath: string): void {
    const redirectUrl = `${window.location.origin}${apiAuthorizationPath}`;

    // "It's important that we do a replace here so that when the user hits the back arrow on the
    // browser he gets sent back to where it was on the app instead of to an endpoint on this
    // component."
    // Why?

    //window.location.replace(redirectUrl);

    redirect(redirectUrl);
  }

  redirectToProfile(): void {
    throw new Error("Not implemented.");

    // this.redirectToApiAuthorizationPath(
    //     AuthrorizationPaths.IdentityManagePath
    // );
  }

  redirectToRegister(): void {
    throw new Error("Not implemented.");

    // this.redirectToApiAuthorizationPath(
    //     `${AuthrorizationPaths.IdentityRegisterPath}?${
    //         QueryParameterNames.ReturnUrl
    //     }=${encodeURI(AuthrorizationPaths.Login)}`
    // );
  }

  // #endregion
}
